<!--
 * @Description: 停车管理--停车订单管理--列表页面
 * @Author: 小广
 * @Date: 2019-06-04 14:06:49
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-10-11 16:54:07
 -->
<template>
  <div class="parkOrderManageList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :exportUrl="exportUrl"
    >
      <template #searchSlot>
        <v-input label="订单编号" v-model="searchParams.serialNumber"></v-input>
        <v-input label="车牌号" v-model="searchParams.carLicence"></v-input>
        <v-select
          label="选择身份"
          v-model="searchParams.payCategory"
          :options="payCategoryOps"
        ></v-select>
        <v-select
          label="套餐类型"
          v-model="searchParams.suitType"
          :options="suitTypeOps"
        ></v-select>
        <v-select
          label="状态"
          v-model="searchParams.applicationStatus"
          :options="payStatusOps"
        ></v-select>
        <v-input label="联系电话" v-model="searchParams.userPhone"></v-input>
         <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-datepicker
          label="办理/到期时间"
          :startTime.sync="searchParams.startDate"
          :endTime.sync="searchParams.endDate"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="查看"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getOrderListURL, exportURL } from './api'
import {
  payCategoryOps,
  payCategoryMap,
  payStatusOps,
  payStatusMap,
  suitTypeOps,
  suitTypeMap,
  rentTypeMap
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'ParkOrderManageList',
  data () {
    return {
      exportUrl: exportURL,
      searchUrl: getOrderListURL,
      payCategoryOps: payCategoryOps,
      suitTypeOps: suitTypeOps.slice(0, -1),
      payStatusOps: payStatusOps,
      communityParams,
      searchParams: {
        communityId: '',
        payCategory: undefined,
        applicationStatus: undefined,
        suitType: undefined,
        serialNumber: '',
        carLicence: '',
        userPhone: '',
        startDate: '',
        endDate: ''
      },
      headers: [
        {
          prop: 'serialNumber',
          label: '订单编号',
          minWidth: '200'
        },
        {
          prop: 'carLicenceStr',
          label: '车牌号'
        },
        {
          prop: 'payCategoryStr',
          label: '身份',
          formatter (row) {
            return payCategoryMap[row.payCategory]
          }
        },
        {
          prop: 'userName',
          label: '真实姓名'
        },
        {
          prop: 'userPhone',
          label: '手机号'
        },
        {
          prop: 'rentTypeStr',
          label: '租赁方式',
          formatter (row) {
            return rentTypeMap[row.rentType]
          }
        },
        {
          prop: 'carSlotName',
          label: '车位/区域'
        },
        {
          prop: 'suitTypeStr',
          label: '套餐类型',
          formatter (row) {
            return suitTypeMap[row.suitType]
          }
        },
        {
          prop: 'purchasePrice',
          label: '价格',
          align: 'right'
        },
        {
          prop: 'purchaseCount',
          label: '购买数量',
          align: 'right'
        },
        {
          prop: 'applicationTimeTag',
          label: '办理/到期时间'
        },
        {
          prop: 'applicationStatusStr',
          label: '状态',
          formatter (row) {
            return payStatusMap[row.applicationStatus]
          }
        },
        {
          prop: 'orgName',
          label: '所属组织'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    edit (row) {
      this.$router.push({
        name: 'parkOrderManageForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
